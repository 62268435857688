<template>
  <div class="collection">
    <div class="center-title">我的收藏</div>
    <div class="sub-title">班级课程</div>
    <ul class="list">
      <router-link :to="'/recordedInfo/'+item.course_id" class="info" v-for="item in collectList" :key="item.collect_id">
        <img class="info-img" :src="item.course_image" alt="">
        <div class="info-content">
          <div class="info-title vertical-ellipsis">
            <span class="learn-title">{{item.course_name}}</span>
            <span class="heart" :class="item.collect?'blue':''" @click="heartBtn(item.course_id)"></span>
          </div>
          <div class="amount">
            <span class="num">￥{{item.course_price}}</span>
            <!-- <span class="pay">{{item.course_name}}人已购</span> -->
          </div>
        </div>
      </router-link>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'Collection',
  data(){
    return {
      collectList: '', //收藏列表
    }
  },
  mounted(){
    this.getCollectList();//获取收藏列表
  },
  methods: {
    //获取收藏列表
    getCollectList(){
      this.$axios.post(`/v1/personal/collectList`, {
        page: 1,
        pre_page: 10,
      }, {useLog: true}).then(res => {
        let data = res.data;

        for(let i=0; i< data.length; i++){
          data[i].collect = true;
        }

        this.collectList = data;
      });
    },
    //收藏按钮
    heartBtn(id){
      this.$axios.post(`/v1/course/detail/collect`, {id}, {useLog: true}).then(res => {
        if(res){
          for(let i=0; i<this.collectList.length; i++){
            if(this.collectList[i].course_id == id){
              this.collectList[i].collect = this.collectList[i].collect ? false : true;
            }
          }
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.sub-title{
  font-size: 16px;
  color: #333;
  margin-bottom: 34px;
}
.list{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  &::after{
    content: "";
    display:block;
    width: 28%;
  }
}
.info{
  width: 230px;
  background: #FFF;
  box-shadow: 0px 0px 14px 0px rgba(0, 12, 40, 0.14);
  border-radius: 6px;
  margin-bottom: 30px;
  .info-img{
    width: 100%;
    height: 142px;
  }
  .info-content{
    padding: 14px;
  }
  .info-title{
    height: 40px;
    line-height: 20px;
    position: relative;
    font-size: 14px;
    color: #333;
    .heart{
      display: block;
      width: 20px;
      height: 20px;
      background: url(~@/assets/heart-gery-ico.png) no-repeat;
      background-size: 100%;
      position: absolute;
      right: 0;
      bottom: 0;
      cursor: pointer;
      &.blue{
        background: url(~@/assets/heart-blue-ico.png) no-repeat;
        background-size: 100%;
      }
    }
  }
  .amount{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    .num{
      font-size: 18px;
      color: #CD1B14;
    }
    .pay{
      font-size: 12px;
      color: #999;
    }
  }
}
</style>
